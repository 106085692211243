import { z } from "zod";
import { extendZodWithOpenApi } from "zod-openapi";
import { jsonBody, successResponse, tagPaths } from "../util.ts";
import { Location } from "./locations.ts";
import * as params from "./params.ts";

extendZodWithOpenApi(z);

// https://gitlab.com/warrenio/platform/framework/config-api/-/blob/f9f78260f32b7b94a1bbace96d0f0e2a1c239e35/api/resources/locations.py#L37-48
const LocationBody = Location.pick({
    display_name: true,
    description: true,
    is_preferred: true,
    is_published: true,
    order_nr: true,
    country_code: true,
    create_resource_disabled: true,
    consul_name_override: true,
}).openapi({ ref: "LocationBody" });

export const locationAdminPaths = tagPaths("admin_locations")({
    "/config/locations/{location}": {
        put: {
            summary: "Update location",
            parameters: [params.location],
            requestBody: jsonBody(LocationBody),
            responses: {
                ...successResponse(z.boolean()),
            },
        },
        delete: {
            summary: "Delete location",
            parameters: [params.location],
            responses: {
                ...successResponse(z.boolean()),
            },
        },
    },
});
