import { isObject } from "@warrenio/utils/isObject";
import { isArray } from "remeda";

const errorFields = new Map<string, Validator[]>();

type Validator = (value: unknown) => boolean;

/** Arbitrary information about an error (sent to error reporting) */
export type ErrorContext = Record<string, unknown>;

/**
 * Register a field of an `Error` subclass to be included in the error reporting data.
 *
 * @param fieldName The name of the field in the error class.
 * @param validator A function that returns if the value is valid (in case multiple classes use the same field name).
 */
export function registerErrorField(fieldName: string, validator: Validator) {
    let validators = errorFields.get(fieldName);
    if (!validators) {
        validators = [];
        errorFields.set(fieldName, validators);
    }
    validators.push(validator);
}

/** Get any registered error fields from {@link cause} and write them into {@link context}. */
export function validateAndAssignErrorContext(cause: Record<string, any>, context: ErrorContext) {
    for (const [field, validators] of errorFields.entries()) {
        if (!(field in cause)) {
            continue;
        }

        const value: unknown = cause[field];
        const isValid = validators.some((validator) => {
            try {
                return validator(value);
            } catch (e) {
                console.error("Error while validating field %s in error context: %o", field, e);
                return false;
            }
        });
        if (!isValid) {
            console.warn("Invalid field %s in error context: %o", field, value);
            continue;
        }

        if (isObject(value) && !isArray(value)) {
            Object.assign(context, value);
        } else {
            context[field] = value;
        }
    }
}

/** Workaround to convert nested errors into context info */
export function errorToContext(e: Error): ErrorContext {
    const c = { message: String(e), name: e.name };
    validateAndAssignErrorContext(e, c);
    return c;
}
