import { atom } from "jotai/vanilla";
import { atomWithLocalStorage } from "../../utils/jotai/atomStorage.ts";
import { impersonationAtom } from "../user/impersonation.store.ts";

/** The API key that was obtained from login */
export const apikeyAtom = atomWithLocalStorage<string | undefined>("apikey", undefined);

export const isLoggedInAtom = atom((get) => get(currentApiKeyAtom) !== undefined);

/** The API key for the "current" user (taking impersonation into account) */
export const currentApiKeyAtom = atom((get) => get(impersonationAtom)?.token ?? get(apikeyAtom));
