import type { Types } from "@honeybadger-io/core";
import type { Noticeable } from "@honeybadger-io/core/build/src/types";
import { Honeybadger } from "@honeybadger-io/react";
import { isObject } from "@warrenio/utils/isObject";
import invariant from "tiny-invariant";
import { honeybadgerConfig } from "../../dev/honeybadgerConfig.ts";
import { addDevVars } from "../../dev/initDev.tsx";
import { isBuiltRelease } from "../../utils/environment.ts";
import { detectTranslationOnce } from "./detectTranslation.ts";
import { validateAndAssignErrorContext } from "./errorFields.tsx";
import { addErrorSuppressor, isErrorSuppressed } from "./errorSuppress.ts";

//#region Configuration

// Error reporting hooks will not be enabled in development (the built-in browser console uncaught error handling is better)
const isEnabled = isBuiltRelease;

//#endregion

//#region Private implementation

/** NB: Do not use this directly, instead create wrapper functions to decouple the error reporting provider from the app */
export let honeybadger_INTERNAL: Honeybadger | undefined = undefined;

if (isEnabled) {
    initHoneybadger();
}

export function initHoneybadger() {
    invariant(honeybadger_INTERNAL === undefined, "Honeybadger already initialized");

    honeybadger_INTERNAL = Honeybadger.configure(honeybadgerConfig);

    honeybadger_INTERNAL.beforeNotify((notice) => {
        if (!notice) {
            return;
        }

        try {
            addStandardContext(notice);
        } catch (e) {
            console.error("Error while adding standard context to error", e);
        }

        flattenCausesToContext(notice);

        try {
            categorizeError(notice);
        } catch (e) {
            console.error("Error while categorizing error", e);
        }

        const suppressed = isErrorSuppressed(notice);
        if (suppressed) {
            // For debugging
            notice.suppressed = true;
        }
        return !suppressed;
    });
}

function addStandardContext(notice: Types.Notice) {
    // Try to detect Google Translate
    notice.context.html_lang = document.documentElement.lang;
    const service = detectTranslationOnce();
    if (service) {
        notice.context.translation_service = service;
    }
}

function categorizeError(notice: Types.Notice) {
    const { correlationId } = notice.context;
    if (typeof correlationId === "string") {
        const [component, rest] = correlationId.split("-");
        if (component && rest) {
            notice.component = component;
        }
    }
}

function getCauses(notice: Types.Notice) {
    const causes = [];

    let current: Record<string, unknown> | undefined = notice;
    while (current) {
        causes.push(current);
        current = isObject(current.cause) ? current.cause : undefined;
    }

    // Reverse causes so the root overwrites the child fields
    causes.reverse();
    return causes;
}

function flattenCausesToContext(notice: Types.Notice) {
    // NB: Must copy object because the original seems to be shared
    const context = { ...notice.context };

    for (const cause of getCauses(notice)) {
        validateAndAssignErrorContext(cause, context);
    }

    notice.context = context;
}

function detailsToNotice(details?: ErrorDetails) {
    if (details === undefined) {
        return undefined;
    }
    const { context, tags } = details;
    return {
        context,
        tags: tags?.join(", "),
    };
}

function internalNotifyError(error: Noticeable, details?: ErrorDetails) {
    const noticeDetails = detailsToNotice(details);
    return honeybadger_INTERNAL?.notify(error, noticeDetails);
}

//#endregion

//#region Standard error suppressions

// Happens on Safari for some reason
addErrorSuppressor((n) => n.message.includes("ResizeObserver loop completed with undelivered"));

// Caused by Google Translate and other extensions. Subsample.
// See:
// - [Make React resilient to DOM mutations from Google Translate](https://github.com/facebook/react/issues/11538)
// - [Google Translate's usage of <font> for text replacement breaks React](https://issues.chromium.org/issues/414071691)
addErrorSuppressor((n) => n.message.includes("Failed to execute 'removeChild' on 'Node'") && Math.random() >= 1 / 20);

//#endregion

//#region Public interface

/** Extra data to pass to error reporting */
export interface ErrorDetails {
    name?: string;
    // `any` instead of `unknown`: https://stackoverflow.com/questions/65799316/why-cant-an-interface-be-assigned-to-recordstring-unknown
    context?: Record<string, any>;
    tags?: string[];
}

export function notifyError(error: unknown, details?: ErrorDetails) {
    const realError = error instanceof Error ? error : String(error);
    return internalNotifyError(realError, details);
}

/**
 * Notify that an error has occurred - without a corresponding {@link Error} object, just a string {@link message}.
 *
 * @see {@link notifyError} - when there is an {@link Error} object available
 */
export function notifyErrorCustom(message: string, details?: ErrorDetails) {
    internalNotifyError(message, { name: "ErrorMessage", ...details });
}

export function addToErrorContext(context: Record<string, unknown>) {
    honeybadger_INTERNAL?.setContext(context);
}

//#endregion

addDevVars({ honeybadger_INTERNAL });
