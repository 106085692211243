import { z } from "zod";
import { extendZodWithOpenApi } from "zod-openapi";
import { isAscending } from "../isAscending.ts";
import { jsonBody, successResponse, tagPaths } from "../util.ts";
import { billing_account_id, simpleSuccessResponse } from "./common.ts";
import { makeOptional } from "./makeOptional.ts";
import {
    MetalLease,
    MetalLeaseFields,
    MetalMachine,
    MetalMachineFields,
    MetalMachineStateFields,
    MetalOs,
    MetalOsCreate,
    MetalOsType,
    MetalOsTypeCreate,
    MetalOsTypeUpdate,
    MetalOsUpdate,
    MetalSpec,
    MetalSpecFields,
    MetalStateHistoryFields,
    os_id_param,
    os_type_id_param,
} from "./metal.ts";
import * as params from "./params.ts";

extendZodWithOpenApi(z);

//#region Admin mutations
const AdminMetalMachineStateFields = z
    .object({
        os_id: MetalMachineStateFields.shape.os_id,
    })
    .describe("Fields for a machine's entry in the State table");

const MachineEditFields = MetalMachineFields.extend(AdminMetalMachineStateFields.shape)
    .extend({
        spec_uuid: MetalSpec.shape.uuid,
    })
    .describe("Fields for creating or updating a machine");

// A machine will always be created with a fixed initial state
export const AdminMetalMachineCreateBody = MachineEditFields.openapi({
    ref: "AdminMetalMachineCreateBody",
});

export const AdminMetalMachineUpdateBody = MachineEditFields.extend({
    status: MetalMachineStateFields.shape.status,
})
    .partial()
    .openapi({
        ref: "AdminMetalMachineUpdateBody",
    });

export const AdminMetalMachine = makeOptional(MetalMachine, { current_state_id: true })
    .extend({
        // TODO: Better type than `MetalSpec`?
        spec: MetalSpec,
        spec_uuid: MetalSpec.shape.uuid,
        status: MetalMachineStateFields.shape.status,
        os_id: MetalMachineStateFields.shape.os_id,
    })
    .openapi({ ref: "AdminMetalMachine" });

/////

export const AdminMetalSpecCreateBody = MetalSpecFields.extend({}).openapi({
    ref: "AdminMetalSpecCreateBody",
});

export const AdminMetalSpecUpdateBody = MetalSpecFields.extend({})
    .partial()
    .openapi({ ref: "AdminMetalSpecUpdateBody" });

/////

export const AdminMetalLeaseCreateBody = MetalLeaseFields.extend({
    machine_uuid: MetalMachine.shape.uuid,
    billing_account_id,
}).openapi({ ref: "AdminMetalLeaseCreateBody" });

export const AdminMetalLeaseUpdateBody = MetalLeaseFields.extend({})
    .partial()
    .openapi({ ref: "AdminMetalLeaseUpdateBody" });
//#endregion

//#region Admin queries
export const AdminMetalMachineItem = makeOptional(MetalMachine, { machine_id: true, current_state_id: true })
    .merge(MetalMachineStateFields)
    .extend({
        // Joins for UI convenience (emulates GraphQL)
        spec: MetalSpec,
        lease: MetalLease.optional(),

        // XXX: These fields should be removed from the backend, redundant with `spec.uuid`
        spec_uuid: MetalSpec.shape.uuid.optional(),
        lease_uuid: MetalLease.shape.uuid.optional(),
    })
    .openapi({ ref: "AdminMetalMachineItem" });

const AdminMetalMachineList = z.array(AdminMetalMachineItem).openapi({ ref: "AdminMetalMachineList" });

/////

const AdminMetalSpecList = z.array(MetalSpec).openapi({ ref: "AdminMetalSpecList" });

/////

const AdminMetalHistoryItem = MetalStateHistoryFields.extend({
    // Joins for UI convenience
    lease: MetalLease.optional(),
}).openapi({ ref: "AdminMetalHistoryItem" });

const AdminMetalHistoryList = z.array(AdminMetalHistoryItem).openapi({ ref: "AdminMetalHistoryList" });
//#endregion

//#region Service queries (for eg. billing) - not used in UI / admin

const MetalChargingEntry = z
    .object({
        changed_at: MetalStateHistoryFields.shape.changed_at,

        // These fields can change during the lease duration so they can not be part of `MetalChargingItem`
        billing_account_id,
        spec_uuid: MetalSpec.shape.uuid,

        is_active: z.boolean().describe("Whether the lease is (was) active and should be billed"),
    })
    .openapi({ ref: "MetalChargingEntry" })
    .describe("Charging ledger entry");

const MetalChargingEntries = z
    .array(MetalChargingEntry)
    .min(1)
    .refine((entries) => isAscending(entries, (e) => e.changed_at), "Entries must be sorted by `changed_at`");

const MetalChargingItem = z
    .object({
        lease_uuid: MetalLease.shape.uuid,
        user_id: MetalLease.shape.user_id, // (just in case)

        // Metadata for invoices
        display_name: MetalLease.shape.display_name,

        entries: MetalChargingEntries,
    })
    .openapi({ ref: "MetalChargingItem" })
    .describe("Charging entries grouped by lease");

const MetalChargingList = z
    .array(MetalChargingItem)
    .openapi({ ref: "MetalChargingList" })
    .describe("Input to charging service (billing system)");

//#endregion

export const metalAdminPaths = tagPaths("admin_metal")({
    // Machines
    "/{location}/admin/metal/machines": {
        get: {
            parameters: [params.location],
            responses: { ...successResponse(AdminMetalMachineList) },
        },
        post: {
            parameters: [params.location],
            requestBody: { ...jsonBody(AdminMetalMachineCreateBody) },
            responses: { ...successResponse(AdminMetalMachine) },
        },
    },
    "/{location}/admin/metal/machines/{uuid}": {
        patch: {
            parameters: [params.location, params.uuid],
            requestBody: { ...jsonBody(AdminMetalMachineUpdateBody) },
            responses: { ...successResponse(AdminMetalMachine) },
        },
        delete: {
            parameters: [params.location, params.uuid],
            responses: { ...simpleSuccessResponse },
        },
    },

    // State history
    "/{location}/admin/metal/machines/{uuid}/history": {
        get: {
            parameters: [params.location, params.uuid],
            responses: { ...successResponse(AdminMetalHistoryList) },
        },
    },

    // Leases
    "/{location}/admin/metal/leases": {
        post: {
            parameters: [params.location],
            requestBody: { ...jsonBody(AdminMetalLeaseCreateBody) },
            responses: { ...successResponse(MetalLease) },
        },
    },
    "/{location}/admin/metal/leases/{uuid}": {
        patch: {
            parameters: [params.location, params.uuid],
            requestBody: { ...jsonBody(AdminMetalLeaseUpdateBody) },
            responses: { ...successResponse(MetalLease) },
        },
    },

    // Specs
    "/{location}/admin/metal/specs": {
        get: {
            parameters: [params.location],
            responses: { ...successResponse(AdminMetalSpecList) },
        },
        post: {
            parameters: [params.location],
            requestBody: { ...jsonBody(AdminMetalSpecCreateBody) },
            responses: { ...successResponse(MetalSpec) },
        },
    },
    "/{location}/admin/metal/specs/{uuid}": {
        patch: {
            parameters: [params.location, params.uuid],
            requestBody: { ...jsonBody(AdminMetalSpecUpdateBody) },
            responses: { ...successResponse(MetalSpec) },
        },
        delete: {
            parameters: [params.location, params.uuid],
            responses: { ...simpleSuccessResponse },
        },
    },

    // Operating systems
    // NB: Listing OS types and OSes is already part of the user-facing API
    "/admin/metal/os": {
        post: {
            requestBody: { ...jsonBody(MetalOsCreate) },
            responses: { ...successResponse(MetalOs) },
        },
    },
    "/admin/metal/os/{os_id}": {
        patch: {
            parameters: [os_id_param],
            requestBody: { ...jsonBody(MetalOsUpdate) },
            responses: { ...successResponse(MetalOs) },
        },
        delete: {
            parameters: [os_id_param],
            responses: { ...simpleSuccessResponse },
        },
    },

    // OS types
    "/admin/metal/os_types": {
        post: {
            requestBody: { ...jsonBody(MetalOsTypeCreate) },
            responses: { ...successResponse(MetalOsType) },
        },
    },
    "/admin/metal/os_types/{os_type_id}": {
        patch: {
            parameters: [os_type_id_param],
            requestBody: { ...jsonBody(MetalOsTypeUpdate) },
            responses: { ...successResponse(MetalOsType) },
        },
        delete: {
            parameters: [os_type_id_param],
            responses: { ...simpleSuccessResponse },
        },
    },

    //#endregion
    //#region Services
    // TODO: What should the path prefix be here? "internal", "service", ...?
    "/{location}/internal/metal/charging": {
        get: {
            description: "Get charging ledger entries for the billing system for all machines",
            parameters: [params.location],
            responses: { ...successResponse(MetalChargingList) },
        },
    },
    //#endregion
});
