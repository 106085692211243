import type { Types } from "@honeybadger-io/core";
import { environmentId } from "../utils/environment.ts";

// NB: Errors will not be reported when `environment` is "development"

function getEnvironment() {
    const { hostname } = window.location;

    // Consider "localhost" and IP addresses as development (even when running the production build)
    if (hostname === "localhost" || /^[\d.]+$/.test(hostname)) {
        return "development";
    }

    // Small hack to consider "test.pilw.io" as a staging environment (even though it uses the production build)
    if (environmentId === "production" && hostname.endsWith(".pilw.io") && hostname.startsWith("test")) {
        return "staging";
    }

    return environmentId;
}

export const honeybadgerConfig: Partial<Types.BrowserConfig> = {
    apiKey: import.meta.env.VITE_HONEYBADGER_API_KEY as string,
    environment: getEnvironment(),
    revision: import.meta.env.VITE_REVISION as string | undefined,
    filters: ["password", "apikey", "email", "access_token"],
    // debug: true,
};

console.info("Environment: %s, revision: %s", honeybadgerConfig.environment, honeybadgerConfig.revision);
