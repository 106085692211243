import { useStore } from "jotai/react";
import { atom, type createStore } from "jotai/vanilla";
import { useEffect } from "react";
import { atomWithLocalStorage } from "../../../utils/jotai/atomStorage.ts";
import { getVisibleSiteBaseUrl } from "../../main/urls.ts";

export function getReferralUrl(referralShareCode: string) {
    const siteBaseUrl = getVisibleSiteBaseUrl();
    // NB: does not include the base path (it is handled by an external redirect)
    return `${siteBaseUrl}/referral/${referralShareCode}`;
}

export const referralCodeAtom = atomWithLocalStorage<string | undefined>("referralCode", undefined);
/** Was the referral code stored for this page load? */
export const didStoreReferralCodeAtom = atom(false);

function setReferralCodeFromLocation(store: ReturnType<typeof createStore>) {
    const query = new URLSearchParams(window.location.search);
    const referralCode = query.get("referral_code");

    if (referralCode) {
        store.set(didStoreReferralCodeAtom, true);
        store.set(referralCodeAtom, referralCode);
    }
}

export function useStoreReferralCode() {
    const store = useStore();
    useEffect(() => {
        setReferralCodeFromLocation(store);
    }, [store]);
}
