import type { z } from "zod";

/** Makes the fields in {@link keys} be `.optional()` */
export function makeOptional<TShape extends z.ZodRawShape>(type: z.ZodObject<TShape, any>, keys: Record<string, true>) {
    const optionals: z.ZodRawShape = {};
    for (const key in keys) {
        if (keys[key] && key in type.shape) {
            optionals[key] = type.shape[key].optional();
        }
    }
    return type.extend(optionals);
}
