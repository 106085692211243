import type { Types } from "@honeybadger-io/core";
import invariant from "tiny-invariant";

/** @returns `true` when reporting the error should be suppressed */
export type ErrorSuppressFn = (notice: Types.Notice) => boolean;

const errorCheckers = new Set<ErrorSuppressFn>();

export function addErrorSuppressor(fn: ErrorSuppressFn) {
    errorCheckers.add(fn);
}

export function isErrorSuppressed(notice: Types.Notice) {
    for (const checker of errorCheckers) {
        try {
            if (checker(notice)) {
                return true;
            }
        } catch (e) {
            console.error("Error while checking error suppression", e);
        }
    }
    return false;
}

type MessageCheck = ((message: string) => boolean) | RegExp | string;

function messageMatches(message: string, check?: MessageCheck) {
    if (!check) {
        return true;
    } else if (typeof check === "function") {
        return check(message);
    } else if (check instanceof RegExp) {
        return check.test(message);
    } else {
        return message.includes(check);
    }
}

export function suppressErrorClass(name: string, messageCheck?: MessageCheck) {
    if (import.meta.env.DEV) {
        invariant(name !== "Error", "Should not suppress the base Error class");
        invariant(name.length > 4, "Probably obfuscated class name being suppressed");
    }

    addErrorSuppressor((notice) => notice.name === name && messageMatches(notice.message, messageCheck));
}
