import { isObject, isObjectWithKey } from "@warrenio/utils/isObject";
import type { Auth0Error } from "auth0-js";
import { registerErrorField } from "../../error/errorFields.tsx";
import { showError } from "../../error/errorStream.ts";
import { suppressErrorClass } from "../../error/errorSuppress.ts";

export class Auth0Exception extends Error {
    name = "Auth0Exception";

    constructor(public readonly original: Auth0Error) {
        super(extractAuth0ErrorMessage(original));
    }
}

registerErrorField("original", isObject);

export class Auth0LoginException extends Auth0Exception {
    name = "Auth0LoginException";
}

export class Auth0SignupException extends Auth0Exception {
    name = "Auth0SignupException";
}

// Suppress "wrong email or password"
suppressErrorClass("Auth0LoginException", /^Wrong\b/i);
suppressErrorClass("Auth0SignupException", /^(Password|error in email)\b/i);
suppressErrorClass("Auth0Exception", /\btoo many requests\b/);

export function extractAuth0ErrorMessage(error: Auth0Error): string {
    const { description, code } = error;
    const original: unknown = error.original;

    if (description === "invalid_domain") {
        return "Email domain is prohibited";
    }
    if (isObjectWithKey(original, "message")) {
        try {
            const parsedErrorData = JSON.parse(original.message as string) as Record<string, unknown>;
            if ("message" in parsedErrorData) {
                return String(parsedErrorData.message);
            }
        } catch (e) {
            showError("Failed to parse error data", e);
        }
    }
    if (description) {
        return description;
    }
    if (code) {
        return `Error code: ${code}`;
    }
    return "Unknown Auth0 error";
}
