import { autoDarkColors } from "../../modules/theme/color/darkModeColor";
import type { ThemeProps, ThemeVarGetters, ThemeVariant } from "../../modules/theme/types";

import png from "./apple-touch-icon.png?url";
import icon from "./favicon.ico?url";
import favicon from "./favicon.svg?url";
import loginIntroBg from "./login-bg.png?url";
import loginImage from "./login-image.png?url";
import loginLogo from "./login-logo.svg";
import logoDark from "./logo-dark.svg?url";
import logo from "./logo.svg?url";

export function makeTheme(variant: ThemeVariant) {
    const { isDark } = variant;

    const baseColors: ThemeVarGetters = autoDarkColors({
        "color-primary": "#ff4a00",
        "color-secondary": "#e5e5e5",
    });

    return {
        ...baseColors,

        "logo-image": isDark ? `url("${logoDark}")` : `url("${logo}")`,

        "header-logo-width": "80px",
        "header-logo-height": "20px",

        "sidenav-item-text-color": isDark ? "#FFFFFF" : "var(--color-text)",
        "sidenav-item-before-display": "none",
        "sidenav-item-hover-bg-color": "var(--color-primary)",
        "sidenav-item-hover-icon-color": "#FFFFFF",
        "sidenav-item-hover-text-color": "#FFFFFF",
        "sidenav-item-active-bg-color": "var(--color-primary)",
        "sidenav-item-active-icon-color": "#FFFFFF",
        "sidenav-item-active-text-color": "#FFFFFF",
        "sidenav-item-active-before-display": "none",

        //#region Login
        "login-body-bg-color": "#FFFFFF",
        "login-body-bg-size": "contain",
        "login-body-text-color": "var(--color-text)",

        "login-intro-display": "flex",
        "login-intro-bg-image": `url("${loginIntroBg}")`,
        "login-intro-bg-size": "contain",
        "login-intro-bg-position": "100% 0",
        "login-intro-text-align": "center",
        "login-intro-flex-direction": "column",
        "login-intro-justify-content": "center",
        "login-intro-align-items": "center",
        "login-intro-padding": "40px",
        "login-title-font-size": "0",
        "login-title-max-width": "",
        "login-subtitle-font-size": "0",

        "login-form-title-font-size": "20px",
        "login-form-bg-color": "var(--color-white)",
        "login-form-text-color": "var(--color-text)",
        "login-form-border-left": "#efefef solid 1px",
        "login-form-input-border-color": "#dbe2e9",
        "login-form-button-border-radius": "var(--input-border-radius)",
        "login-form-or-text-color": "#dbe2e9",
        "login-form-or-border-color": "#dbe2e9",
        "login-form-google-bg-color": "#007bf5",
        "login-form-more-text-color": "var(--color-primary)",
        "login-form-more-bg-color": "#ffffff",
        "login-form-more-border-color": "var(--color-primary)",
        //#endregion
    } satisfies ThemeVarGetters;
}

export const props: ThemeProps = {
    favicon,
    icon,
    png,
    loginLogoImg: {
        src: loginLogo,
        style: {
            maxWidth: "240px",
        },
    },
    loginIntroImg: {
        src: loginImage,
        style: {
            maxWidth: "640px",
        },
    },
    vatText: "GST",
};
