import L from "./Login.module.css";

import { getOwn } from "@warrenio/utils/collections/getOwnProperty";
import { useAtomValue, useSetAtom, useStore } from "jotai/react";
import { Suspense, useEffect, useState, type ReactElement } from "react";
import { Link } from "react-aria-components";
import { useForm, type SubmitHandler } from "react-hook-form";
import { IsoCerts } from "../../components/IsoCerts.tsx";
import { WButton, WSubmitButton } from "../../components/button/WButton.tsx";
import { WHookForm } from "../../components/forms/WHookForm.tsx";
import { MaskIcon } from "../../components/icon/MaskIcon.tsx";
import * as mswInit from "../../mock-api/msw/mswInit.ts";
import { cn } from "../../utils/classNames.ts";
import { apikeyAtom } from "../api/apikey.store.ts";
import { apiPrefixAtom } from "../api/createApiClient.ts";
import { didStoreReferralCodeAtom } from "../billing/referral/referralLogic.ts";
import { notifyError } from "../error/errorReporting.tsx";
import { getCurrentPath } from "../main/urls.ts";
import { useLoadChatProvider } from "../support/WChatProvider.tsx";
import { useThemeProps } from "../theme/useTheme.ts";
import { lastApiKeyAtom } from "./ApiKeyLogin.store.ts";
import { InputRow } from "./InputRow.tsx";
import { LoginBox, LoginLayout } from "./LoginLayout.tsx";
import { Auth0LoginForm, type AfterLoginProps } from "./auth0/Auth0Login.tsx";
import { auth0ProviderAtom, exceptionToMessage } from "./auth0/auth0Provider.ts";
import { authProviderAtom, type AuthProvider } from "./authProvider.ts";

interface Inputs {
    apikey: string;
    enableMockApi: boolean;
}

const defaultMockApiKey = "mock-key";

function ApiKeyLoginForm() {
    //#region Hooks
    const setApikey = useSetAtom(apikeyAtom);

    const store = useStore();
    const apiPrefix = useAtomValue(apiPrefixAtom);
    const lastApiKey = getOwn(store.get(lastApiKeyAtom), apiPrefix) ?? "";

    const form = useForm<Inputs>({
        defaultValues: {
            enableMockApi: mswInit.isMockApiEnabled(),
            apikey: lastApiKey,
        },
    });

    // Update the form field value when the selected API key changes
    useEffect(() => {
        form.setValue("apikey", lastApiKey, { shouldTouch: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastApiKey]);

    //#endregion

    const { register, watch } = form;

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        const { enableMockApi, apikey } = data;

        const enteredApiKey = apikey.trim();
        const finalApikey = enableMockApi ? defaultMockApiKey : enteredApiKey;

        const mswChanged = mswInit.isMockApiEnabled() !== enableMockApi;

        // NB: must come first so no requests are made before MSW is initialized
        mswInit.setEnabled(enableMockApi);

        setApikey(finalApikey);

        store.set(lastApiKeyAtom, (x) => ({ ...x, [apiPrefix]: enteredApiKey }));

        if (mswChanged) {
            // Reload the page to clear various caches after MSW is enabled/disabled
            window.location.reload();
        }
    };

    const mockApiRow = mswInit.isSupported() ? (
        <div className="pb-2">
            <input id="enableMockApi" type="checkbox" {...register("enableMockApi")} />{" "}
            <label htmlFor="enableMockApi">Enable mock API</label>
        </div>
    ) : null;

    return (
        <WHookForm form={form} onSubmit={onSubmit}>
            <InputRow name="apikey" label="API key" autoComplete="on" isRequired={!watch("enableMockApi")} autoFocus />

            {mockApiRow}

            <WSubmitButton data-testid="logInButton" size="lg" fontSize="font-size-subtitle" className={L.Button}>
                Log In
            </WSubmitButton>
        </WHookForm>
    );
}

interface ForgotInputs {
    email: string;
}

function ForgotPassword({
    onBack,
    onPostSubmit,
}: {
    provider: AuthProvider;
    onBack: () => void;
    onPostSubmit: (username: string) => void;
}) {
    //#region Hooks
    const form = useForm<ForgotInputs>({
        defaultValues: {
            email: "",
        },
    });
    const authProvider = useAtomValue(auth0ProviderAtom);
    const [error, setError] = useState<string | null>(null);
    //#endregion

    const onSubmit: SubmitHandler<ForgotInputs> = async ({ email }) => {
        try {
            setError(null);
            await authProvider.forgotPassword({ email });
            onPostSubmit(email);
        } catch (error) {
            notifyError(error, { tags: ["ForgotPassword"] });
            setError(exceptionToMessage(error));
        }
    };

    return (
        <LoginBox title="Forgot password">
            <p>Please enter your email address. We will send you an email to reset your password.</p>

            <WHookForm form={form} onSubmit={onSubmit}>
                <InputRow name="email" type="email" label="Email" isRequired autoFocus />

                <WSubmitButton size="lg" fontSize="font-size-subtitle" className={L.Button}>
                    Send Email
                </WSubmitButton>
            </WHookForm>

            <div className="pt-5 pb-1 text-center">
                <WButton className={cn(L.More)} action={onBack}>
                    Back
                </WButton>
            </div>

            {error && (
                <div className="react-aria-FieldError pt-2">
                    <b>Error:</b> {error}
                </div>
            )}
        </LoginBox>
    );
}

function StandardLoginBox({
    provider,
    onForgotPassword,
    onPostSignup,
    apiKeyOnly,
}: {
    provider: AuthProvider;
    onForgotPassword: () => void;
    apiKeyOnly: boolean;
} & AfterLoginProps) {
    const store = useStore();
    // Default to sign up with referral code
    const [isSignup, setIsSignup] = useState(store.get(didStoreReferralCodeAtom));
    const { loginGoogleButton } = useThemeProps();

    return (
        <LoginBox title={isSignup ? "Sign up" : "Log in"}>
            {!apiKeyOnly ? <Auth0LoginForm isSignup={isSignup} onPostSignup={onPostSignup} /> : <ApiKeyLoginForm />}

            <div className={L.Border}>
                <div className={L.Or}>OR</div>
            </div>

            <WButton
                size="lg"
                icon="jp-google-icon"
                fontSize="font-size-subtitle"
                className={cn(L.Button, L.Google)}
                action={async () =>
                    await provider.socialLogin({ connectionId: "google-oauth2", redirectPath: getCurrentPath() })
                }
                {...loginGoogleButton}
            >
                {isSignup ? "Sign up with Google" : "Log in with Google"}
            </WButton>

            {!isSignup && (
                <div className="pt-5 pb-1 text-center">
                    <Link onPress={onForgotPassword}>Forgot password?</Link>
                </div>
            )}

            <div className="pt-5 text-center">
                {isSignup && <div className="mb-2">Already have an account?</div>}
                <WButton className={cn(L.More)} action={() => setIsSignup((x) => !x)}>
                    {isSignup ? "Log In" : "Create An Account"}
                </WButton>
            </div>

            <div className={L.Certs}>
                <IsoCerts />
            </div>
        </LoginBox>
    );
}

function WaitingForEmailConfirmation({
    email,
    onBack,
    afterSignup,
}: {
    email: string;
    onBack: () => void;
    afterSignup: boolean;
}) {
    return (
        <LoginBox title="Confirm email">
            <div className={cn(L.Email, "text-center")}>
                <div>
                    <MaskIcon className="jp-mail-icon size-2rem" />
                </div>
                <p>{email}</p>
            </div>

            <p className="text-center">
                {afterSignup
                    ? "Confirm your email address by clicking the verification link we just sent to your inbox."
                    : "Change your password by clicking the verification link we just sent to your inbox."}
            </p>

            <div className="pt-5 pb-1 text-center">
                <WButton className={cn(L.More)} action={onBack}>
                    Back
                </WButton>
            </div>
        </LoginBox>
    );
}

type LoginUiStateType =
    | "login_signup"
    | "forgot_password"
    | "waiting_signup_confirmation"
    | "waiting_forgot_confirmation";

export function UniversalLoginRoot({ apiKeyOnly }: { apiKeyOnly?: boolean }) {
    //#region Hooks
    const [uiState, setUiState] = useState<LoginUiStateType>("login_signup");
    const [confirmationEmail, setConfirmationEmail] = useState("");
    const provider = useAtomValue(authProviderAtom);
    //#endregion

    let displayElement: ReactElement;

    switch (uiState) {
        case "forgot_password":
            displayElement = (
                <ForgotPassword
                    provider={provider}
                    onBack={() => setUiState("login_signup")}
                    onPostSubmit={(username) => {
                        setConfirmationEmail(username);
                        setUiState("waiting_forgot_confirmation");
                    }}
                />
            );
            break;

        case "waiting_signup_confirmation":
        case "waiting_forgot_confirmation":
            displayElement = (
                <WaitingForEmailConfirmation
                    email={confirmationEmail}
                    onBack={() => setUiState("login_signup")}
                    afterSignup={uiState === "waiting_signup_confirmation"}
                />
            );
            break;

        default:
            displayElement = (
                <StandardLoginBox
                    apiKeyOnly={apiKeyOnly ?? !!provider.isFake}
                    provider={provider}
                    onForgotPassword={() => setUiState("forgot_password")}
                    onPostSignup={({ email, emailVerified }) => {
                        setConfirmationEmail(email ?? "");
                        setUiState(emailVerified ? "login_signup" : "waiting_signup_confirmation");
                    }}
                />
            );
    }

    return (
        <LoginLayout>
            <Suspense>
                <WLoginChatLoader />
            </Suspense>
            {displayElement}
        </LoginLayout>
    );
}

// NB: Chat is *not* loaded in Auth0 callback page, it will only be instantiated in the logged-in layout, to make sure the user data is available before the chat provider is loaded.
function WLoginChatLoader() {
    useLoadChatProvider();
    return null;
}
