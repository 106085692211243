import type { CardFieldsOnApproveData } from "@paypal/paypal-js";
import { randomUUID } from "@warrenio/utils/ponyfill/randomUUID";

export type ExtendedCardFieldsOnApproveData = CardFieldsOnApproveData & {
    vaultSetupToken?: string;
};

const SESSION_ID_KEY = "paypalSessionId";

export function getPaypalSessionId(): string {
    const valueInSessionStorage = sessionStorage.getItem(SESSION_ID_KEY);
    if (valueInSessionStorage) {
        return valueInSessionStorage;
    }
    const newVaue = randomUUID();
    sessionStorage.setItem(SESSION_ID_KEY, newVaue);
    return newVaue;
}

export function clearPaypalSessionId() {
    sessionStorage.removeItem(SESSION_ID_KEY);
}

export class PayPalVaultLoadException extends Error {
    name = "PayPalVaultLoadError";
    constructor(options?: { cause?: unknown }) {
        super("Failed to load PayPal.", options);
    }
}
