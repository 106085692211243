import { makeOnce } from "@warrenio/utils/makeOnce";
import { discardPromise } from "@warrenio/utils/promise/discardPromise";
import { useEffect } from "react";
import { getThemeProps_ONCE } from "./modules/theme/useTheme.ts";
import { loadScript } from "./utils/loadScript.ts";

const loadedScripts = new Map<string, Promise<Event>>();

function loadScriptOnce(src: string) {
    let promise = loadedScripts.get(src);
    if (!promise) {
        promise = loadScript(src);
        loadedScripts.set(src, promise);
    }
    return promise;
}

export const loadThemeScripts = makeOnce(async () => {
    const { themeScripts } = getThemeProps_ONCE();
    for (const { src, waitForLoad = false } of themeScripts ?? []) {
        const promise = loadScriptOnce(src);
        if (waitForLoad) {
            try {
                await promise;
            } catch (e) {
                console.error(`Failed to load script: ${src}`, e);
            }
        } else {
            discardPromise(promise);
        }
    }
});

export function useLoadThemeScripts() {
    useEffect(() => discardPromise(loadThemeScripts()), []);
    return null;
}
