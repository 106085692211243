import { autoDarkColors } from "../../modules/theme/color/darkModeColor";
import type { ThemeProps, ThemeVarGetters, ThemeVariant } from "../../modules/theme/types";

import png from "./apple-touch-icon.png?url";
import icon from "./favicon.ico?url";
import favicon from "./favicon.svg?url";
import loginIntroBg from "./login-bg.jpg?url";
import logoDark from "./logo-dark.svg?url";
import loginLogo from "./logo.svg";
import logo from "./logo.svg?url";

export function makeTheme(variant: ThemeVariant) {
    const { isDark } = variant;

    const baseColors: ThemeVarGetters = autoDarkColors({
        "color-primary": "#00A0AA",
        "color-secondary": "#e5e5e5",
    });

    return {
        ...baseColors,

        "logo-image": isDark ? `url("${logoDark}")` : `url("${logo}")`,

        "header-logo-width": "58px",
        "header-logo-height": "20px",

        "sidenav-item-text-color": isDark ? "#FFFFFF" : "var(--color-text)",
        "sidenav-item-before-display": "none",
        "sidenav-item-hover-bg-color": "var(--color-primary)",
        "sidenav-item-hover-icon-color": "#FFFFFF",
        "sidenav-item-hover-text-color": "#FFFFFF",
        "sidenav-item-active-bg-color": "var(--color-primary)",
        "sidenav-item-active-icon-color": "#FFFFFF",
        "sidenav-item-active-text-color": "#FFFFFF",
        "sidenav-item-active-before-display": "none",

        //#region Login
        "login-body-bg-color": "#FFFFFF",
        "login-body-bg-size": "cover",
        "login-body-text-color": "var(--color-text)",
        "login-justify-content": "space-between",
        "login-align-items": "stretch",

        "login-intro-display": "flex",
        "login-intro-bg-image": `url("${loginIntroBg}")`,
        "login-intro-bg-size": "cover",
        "login-intro-bg-position": "50% 0",
        "login-intro-text-align": "left",
        "login-intro-flex-direction": "column",
        "login-intro-justify-content": "flex-end",
        "login-intro-align-items": "flex-start",
        "login-intro-padding": "60px",
        "login-title-font-size": "28px",
        "login-title-text-color": "",
        "login-title-font-weight": 600,
        "login-title-line-height": 1,
        "login-title-max-width": "400px",
        "login-title-padding": "0 0 16px 0",
        "login-subtitle-font-size": "16px",
        "login-subtitle-max-width": "400px",

        "login-form-title-font-size": "20px",
        "login-form-bg-color": "var(--color-white)",
        "login-form-text-color": "var(--color-text)",
        "login-form-border-left": "#efefef solid 1px",
        "login-form-input-border-color": "#efefef",
        "login-form-button-border-radius": "var(--input-border-radius)",
        "login-form-or-text-color": "#efefef",
        "login-form-or-border-color": "#efefef",
        "login-form-google-bg-color": "#007bf5",
        "login-form-more-text-color": "var(--color-primary)",
        "login-form-more-bg-color": "var(--color-white)",
        "login-form-more-border-color": "var(--color-primary)",
        //#endregion
    } satisfies ThemeVarGetters;
}

export const props: ThemeProps = {
    favicon,
    icon,
    png,
    loginLogoImg: {
        src: loginLogo,
        style: {
            maxWidth: "120px",
        },
    },
    welcomeText:
        "We've assembled some links to get you started. Deploy your machines and manage your account from our platform",
};
