import "./styles/index.css";

import { Provider, useAtomValue, useSetAtom } from "jotai/react";
import { useHydrateAtoms } from "jotai/utils";
import { getDefaultStore } from "jotai/vanilla";
import React, { type ReactNode, StrictMode } from "react";
import { WButton } from "./components/button/WButton.tsx";
import { PageLoadingSuspense } from "./components/loading/Loading";
import { configFetchedAtom, configWaitFetchAtom } from "./config.fetch.ts";
import { mockConfigAtom } from "./config.mock.ts";
import { injectedConfigAtom } from "./config.ts";
import * as debugConfig from "./debugConfig";
import { addDevVars } from "./dev/initDev";
import { isMockApiEnabled } from "./mock-api/msw/mswInit.ts";
import { ApiCredentialsError } from "./modules/api/ApiError.ts";
import { isLoggedInAtom } from "./modules/api/apikey.store.ts";
import { logoutAction } from "./modules/api/logoutAction.ts";
import { PaymentReturn } from "./modules/billing/PaymentReturn.tsx";
import { useStoreReferralCode } from "./modules/billing/referral/referralLogic.ts";
import { ErrorBox } from "./modules/error/ErrorBox.tsx";
import { showError } from "./modules/error/errorStream.ts";
import { ErrorToaster } from "./modules/error/ErrorToaster.tsx";
import { errorInfoAsString, errorMessageAsString } from "./modules/error/errorToString.tsx";
import { ReportingErrorBoundary } from "./modules/error/ReportingErrorBoundary.tsx";
import { getAuth0CallbackParams } from "./modules/login/auth0/auth0Callback.ts";
import { Auth0Callback } from "./modules/login/auth0/Auth0Login.tsx";
import { LoginsRoot } from "./modules/login/LoginsRoot.tsx";
import { ColorSchemeSwitcher } from "./modules/main/ColorSchemeSwitcher.tsx";
import { getCurrentPath } from "./modules/main/urls.ts";
import { initTheme } from "./modules/theme/init.ts";
import { UserErrorContext } from "./modules/user/UserErrorContext.ts";
import { path_auth0Callback, path_paymentReturn } from "./paths.ts";
import { useLoadThemeScripts } from "./ScriptLoader.tsx";
import { matchPath } from "./utils/paths.ts";

const MainRoot = React.lazy(() => import("./modules/main/MainRoot.tsx"));

// NB: Eagerly load the login module to reduce the time to interactive and prevent generating too many chunks
// const LoginsRoot = React.lazy(() => import("./modules/login/LoginsRoot.tsx"));

addDevVars({ store: getDefaultStore() });

/** Simple routing for the root component based on the current path and whether the user is logged in. */
function useRootRouteComponent(): ReactNode {
    //#region Hooks
    const loggedIn = useAtomValue(isLoggedInAtom);
    //#endregion

    //#region Pre-authentication routes
    const path = getCurrentPath();

    if (matchPath(path_auth0Callback, path) != null) {
        const params = getAuth0CallbackParams();
        if (params) {
            return <Auth0Callback params={params} />;
        } else {
            showError("No Auth0 callback params found");
        }
    } else if (matchPath(path_paymentReturn, path) != null) {
        return <PaymentReturn />;
    }
    //#endregion

    // Logged in / out switch
    if (loggedIn) {
        return <MainRoot />;
    } else {
        return <LoginsRoot />;
    }
}

function RootSwitch() {
    useInitializeRoot();

    useStoreReferralCode();

    const component = useRootRouteComponent();

    useLoadThemeScripts();

    return component;
}

/**
 * Pre-load configuration and initialize the theme.
 *
 * NB: Must run before any components are rendered.
 */
function useInitializeRoot() {
    const isMockApi = isMockApiEnabled();

    // Makes sure config has been fetched before proceeding (this will suspend until it is)
    useAtomValue(isMockApi ? mockConfigAtom : configWaitFetchAtom);

    useHydrateAtoms([[injectedConfigAtom, isMockApi ? mockConfigAtom : configFetchedAtom]]);

    // NB: Initialize theme before rendering any components but after loading config (since it uses `siteTheme`)
    initTheme();
}

export function Root() {
    // TODO: <LoadingSuspense> will be rendered without theme styles when config loading takes too long
    let rootNode = (
        <MainErrorBoundary>
            <Provider store={getDefaultStore()}>
                <UserErrorContext />
                {import.meta.env.DEV && <ErrorToaster />}
                <ColorSchemeSwitcher />
                <PageLoadingSuspense>
                    <RootSwitch />
                </PageLoadingSuspense>
            </Provider>
        </MainErrorBoundary>
    );
    if (import.meta.env.DEV && debugConfig.reactStrictMode) {
        rootNode = <StrictMode>{rootNode}</StrictMode>;
    }
    return rootNode;
}

export default Root;

//#region Error handling

export function MainErrorMessage({ error }: { error: unknown }) {
    const message = errorMessageAsString(error);
    const info = errorInfoAsString(error);
    const logout = useSetAtom(logoutAction);

    const isAuthError = error instanceof ApiCredentialsError;

    return (
        <ErrorBox>
            <p className="text-left mb-2">{message}</p>
            {info && <div className="text-muted mb-2">({info})</div>}
            <div className="HStack mt-2 gap-2">
                {!isAuthError && (
                    <WButton action={() => location.reload()} color="primary">
                        Reload page
                    </WButton>
                )}
                <WButton
                    action={async () => {
                        await logout();
                        location.reload();
                    }}
                    color="primary"
                >
                    Log out
                </WButton>
            </div>
        </ErrorBox>
    );
}

function MainErrorBoundary({ children }: { children: ReactNode }) {
    return (
        <ReportingErrorBoundary FallbackComponent={MainErrorMessage} tags="main">
            {children}
        </ReportingErrorBoundary>
    );
}

//#endregion
