import { atom } from "jotai/vanilla";
import { useEffect } from "react";
import { listenAtom } from "../../utils/jotai/listenAtom.ts";
import { mergeLoadedQueries, queryThen } from "../../utils/query/mergeQueries.ts";
import { addToErrorContext } from "../error/errorReporting.tsx";
import { isInternalEmail, userDataAtom, userIsAdminAtom } from "./apiOperations.ts";

const userErrorContextAtom = atom((get) => queryThen(get(userDataAtom), (data) => ({ user_id: data.id })));

const adminErrorContextAtom = atom((get) =>
    mergeLoadedQueries({ user_is_admin: get(userIsAdminAtom), user: get(userDataAtom) }, ({ user_is_admin, user }) => {
        const email = user.profile_data.email;
        return {
            user_is_admin,
            // Do not send user e-mails for non-company/internal users (for privacy reasons)
            user_email: user_is_admin || email == null || isInternalEmail(email) ? email : undefined,
        };
    }),
);

/** Load the user profile data and forward it to the error reporting service */
export function UserErrorContext() {
    useEffect(
        () =>
            listenAtom(userErrorContextAtom, ({ data }) => {
                if (data !== undefined) {
                    addToErrorContext(data);
                }
            }),
        [],
    );

    useEffect(
        () =>
            listenAtom(adminErrorContextAtom, ({ data }) => {
                if (data !== undefined) {
                    addToErrorContext(data);
                }
            }),
        [],
    );

    return null;
}
