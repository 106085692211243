import { z, type ZodTypeAny } from "zod";
import { extendZodWithOpenApi } from "zod-openapi";
import { errorResponse, jsonBody, successResponse, tagPaths, unref } from "../util.ts";
import {
    billing_account_id,
    created_at,
    datetime,
    deleted_at,
    int,
    mac,
    simpleSuccessResponse,
    updated_at,
    user_id,
    uuid,
} from "./common.ts";
import { makeOptional } from "./makeOptional.ts";
import * as params from "./params.ts";

extendZodWithOpenApi(z);

/** Declare an internal-only field type */
function internal<T extends z.ZodType<any, any>>(type: T) {
    return type.describe("[Internal-only field]").openapi({ deprecated: true });
}

const deprecatedOverride = z.unknown().optional().openapi({ deprecated: true });

//#region Field types

const string_id = z.string().regex(/^[a-z][a-z0-9_\-]*$/);

const is_deleted = z.boolean().describe("Whether this entity has been deleted");

export const MetalOsId = string_id.describe("Operating system ID").openapi({ ref: "MetalOsId" });
export const MetalOsTypeId = string_id.describe("Operating system type ID").openapi({ ref: "MetalOsTypeId" });

//#endregion

//#region Parameters

export const os_id_param = MetalOsId.openapi({ param: { name: "os_id", in: "path", ref: "os_id" } });
export const os_type_id_param = MetalOsTypeId.openapi({ param: { name: "os_type_id", in: "path", ref: "os_type_id" } });

//#endregion

///---- Conventions -----

// The types named `*Fields` are parts of an entity that can be created/updated trivially via admin

///----- Spec -----

export const MetalSpecFields = z
    .object({
        title: z.string(),
        subtitle: z.string(),
        description: z.string(),

        is_visible: z.boolean().describe("Whether this spec is visible when creating a machine"),
    })
    .openapi({ ref: "MetalSpecFields" });

export const MetalSpec = MetalSpecFields.extend({
    // XXX: Optional for mock API, this field should be removed from the spec and moved to a mock-API only type
    spec_id: internal(int).optional(),
    uuid: uuid.describe("Spec UUID"),

    created_at,
    updated_at,
    deleted_at,

    /** Soft delete since history entries refer to this entity */
    is_deleted,
})
    .describe("A group of machines with similar hardware characteristics")
    .openapi({ ref: "MetalSpec" });

///----- Machine -----

export const MetalMachineFields = z
    .object({
        label: z.string(),

        // XXX: Optional for mock API
        spec_id: internal(int).optional(),
        mac_addresses: z.array(mac).describe("List of MAC addresses of the main network card"),
        ip_public_v4: z.string().ip("v4"),
        ip_public_v6: z.string().ip("v6").optional().nullable(),

        ssh_credentials: z.string().optional().nullable(),

        admin_notes: z.string().optional().nullable().describe("Internal notes about the machine"),
    })
    .openapi({ ref: "MetalMachineFields" });

export const MetalMachine = MetalMachineFields.extend({
    // XXX: For mock API only
    machine_id: internal(int).optional(),
    uuid: uuid.describe("Machine UUID"),

    // NB: These fields apply only to updates to the machine, not to the state
    created_at,
    updated_at: updated_at.optional().nullable(),

    current_state_id: int.describe("FK to the latest state in the history"),
})
    .describe("A physical machine")
    .openapi({ ref: "MetalMachine" });

///----- Lease -----

export const MetalLeaseFields = z
    .object({
        requested_os_id: MetalOsId,
        display_name: z.string(),
    })
    .openapi({ ref: "MetalLeaseFields" });

export const MetalLease = MetalLeaseFields.extend({
    // XXX: Optional for mock API
    lease_id: internal(int).optional(),
    uuid,

    // XXX: Optional for mock API
    machine_id: internal(int).optional(),
    machine_uuid: uuid.optional(),
    user_id,

    created_at,
    updated_at,
})
    .describe("The ownership of a machine by a specific user (for a period of time)")
    .openapi({ ref: "MetalLease" });

///----- State -----

//#region Status enum and its subsets
export const MetalStatus = z
    .enum(["available", "pending", "in_use", "cleaning", "offline", "deleted"])
    .openapi({
        example: "available",
        ref: "MetalStatus",
    })
    .describe("States for a machine");

export const MetalLeasedStatus = z
    .enum(["pending", "in_use"])
    .openapi({
        example: "in_use",
        ref: "MetalLeasedStatus",
    })
    .describe("Valid states for a machine that is currently leased. Subset of `MetalStatus`.");

export const MetalUnleasedStatus = z
    .enum(["available", "cleaning", "offline"])
    .openapi({
        ref: "MetalUnleasedStatus",
    })
    .describe("Valid states for a machine that is not currently leased. Subset of `MetalStatus`.");
//#endregion

export const MetalMachineStateFields = z
    .object({
        lease_id: internal(int).optional().nullable(),
        billing_account_id: billing_account_id.optional().nullable(),
        os_id: MetalOsId.optional().nullable(),
        status: MetalStatus,
    })
    .describe("Fields that should be part of a machine but are tracked separately (for history)");

// NB: Need to put the fields in a separate object because Zod's `refine()` does not allow using `.pick()`: https://github.com/colinhacks/zod/issues/1245
export const MetalStateHistoryFields = MetalMachineStateFields.extend({
    state_id: int,
    changed_at: datetime,
    ended_at: datetime.optional().nullable(),

    acting_user_id: unref(user_id).describe("User who initiated the change"),
    action: z.enum([
        "admin_create",
        "admin_update",
        "admin_delete",
        "admin_lease_create",
        "user_request",
        "user_release",
    ]),

    machine_id: internal(int),
});

export const MetalStateHistory = MetalStateHistoryFields
    // Check invariants
    .refine(
        (s) => s.lease_id == null || (s.status !== "available" && s.status !== "offline" && s.status !== "deleted"),
        (s) => ({ path: ["status"], message: `Machine must not be leased in "${s.status}" status` }),
    )
    .refine(
        (s) => (s.status !== "in_use" && s.status !== "pending") || s.lease_id != null,
        "In-use machine must be leased",
    )
    .refine((s) => s.lease_id == null || s.billing_account_id != null, "Leased machine must have a billing account")
    .refine(
        (s) => s.lease_id != null || s.billing_account_id == null,
        "Unleased machine must not have a billing account",
    )
    .describe("Current and previous states of machines")
    .openapi({ ref: "MetalStateHistory" });

///----- OS -----

const MetalOsFields = z
    .object({
        os_type_id: MetalOsTypeId,
        is_published: z.boolean().describe("Whether this OS is visible when creating a machine"),
        version_title: z.string(),
    })
    .openapi({ ref: "MetalOsFields" });

export const MetalOs = MetalOsFields.extend({
    os_id: MetalOsId,

    is_deleted,
    updated_at,
})
    .describe("An operating system that can be installed on a machine (specific version)")
    .openapi({ ref: "MetalOs" });

export const MetalOsCreate = MetalOsFields.extend({
    os_id: MetalOs.shape.os_id,
}).openapi({ ref: "MetalOsCreate" });

export const MetalOsUpdate = MetalOsFields.extend({}).partial().openapi({ ref: "MetalOsUpdate" });

///----- OS type -----

const MetalOsTypeFields = z.object({
    title: z.string(),
    icon: z.string(),
});

export const MetalOsType = MetalOsTypeFields.extend({
    os_type_id: MetalOsTypeId,

    is_deleted,
    updated_at,
})
    .describe("A category of operating systems (eg. CentOS, Debian, Windows)")
    .openapi({ ref: "MetalOsType" });

export const MetalOsTypeCreate = MetalOsTypeFields.extend({
    os_type_id: MetalOsType.shape.os_type_id,
}).openapi({ ref: "MetalOsTypeCreate" });

export const MetalOsTypeUpdate = MetalOsTypeFields.extend({}).partial().openapi({ ref: "MetalOsTypeUpdate" });

//#endregion

export const metalSchemas: Record<string, ZodTypeAny> = {
    MetalStateHistory,
    // XXX: Only for mock API
    MetalMachine,
};

//#region Public / user frontend data model

//#region Queries
export const MetalListItem = MetalLease.extend({
    spec: MetalSpec.pick({
        uuid: true,

        title: true,
        subtitle: true,
        description: true,
    })
        .extend({
            spec_id: deprecatedOverride,
        })
        .describe("Machine hardware specification"),

    os_id: MetalStateHistoryFields.shape.os_id,
    billing_account_id,

    ...MetalMachine.pick({
        ssh_credentials: true,
        ip_public_v4: true,
        ip_public_v6: true,
        mac_addresses: true,
    }).shape,

    status: MetalLeasedStatus,
})
    .describe("Machine viewed from the perspective of a single user (used in lists and detail view)")
    .openapi({ ref: "MetalListItem" });

export const MetalListResponse = z.array(MetalListItem).openapi({ ref: "MetalListResponse" });

export const MetalOsListResponse = z
    .object({
        os_types: z.array(MetalOsType),
        os: z.array(MetalOs),
    })
    .openapi({ ref: "MetalOsListResponse" });

// XXX: Backward compat `makeOptional`, replace with `.omit` when deployed
export const MetalSpecListItem = makeOptional(MetalSpec, {
    created_at: true,
    updated_at: true,
    deleted_at: true,
    is_visible: true,
    is_deleted: true,
})
    .extend({
        is_available: z.boolean().describe("Whether machines with this spec can be created right now"),
        ready_os_ids: z
            .array(MetalOsId)
            .describe("List of OS IDs that are immediately available for machines with this spec"),
    })
    .openapi({ ref: "MetalSpecListItem" });

export const MetalSpecListResponse = z.array(MetalSpecListItem).openapi({ ref: "MetalSpecListResponse" });
//#endregion

//#region Mutations
export const MetalLeaseCreateBody = z
    .object({
        spec_uuid: MetalSpec.shape.uuid,
        display_name: MetalLease.shape.display_name,
        requested_os_id: MetalLease.shape.requested_os_id,
        billing_account_id,
    })
    .openapi({ ref: "MetalLeaseCreateBody" });

export const MetalLeaseUpdateBody = z
    .object({
        display_name: MetalLease.shape.display_name,
    })
    .openapi({ ref: "MetalLeaseUpdateBody" });
//#endregion

export const metalPaths = tagPaths("metal")({
    "/{location}/metal/leases": {
        get: {
            description: "List of machines leased by the user",
            parameters: [params.location],
            responses: { ...successResponse(MetalListResponse) },
        },
        post: {
            description: "Request to lease a machine with a specific spec",
            parameters: [params.location],
            requestBody: { ...jsonBody(MetalLeaseCreateBody) },
            responses: {
                ...successResponse(MetalListItem),
                ...errorResponse("No available machines"),
            },
        },
    },
    "/{location}/metal/leases/{uuid}": {
        patch: {
            description: "Update lease details",
            parameters: [params.location, params.uuid],
            requestBody: { ...jsonBody(MetalLeaseUpdateBody) },
            // TODO: Should probably return the updated `MetalListItem`
            responses: { ...simpleSuccessResponse },
        },
        delete: {
            description: "Release a machine",
            parameters: [params.location, params.uuid],
            responses: {
                ...simpleSuccessResponse,
                ...errorResponse("Too soon to release machine"),
            },
        },
    },
    "/{location}/metal/specs": {
        get: {
            description: "List machine types (specs)",
            parameters: [params.location],
            responses: { ...successResponse(MetalSpecListResponse) },
        },
    },
    "/metal/os": {
        get: {
            description: "List available operating systems",
            responses: { ...successResponse(MetalOsListResponse) },
        },
    },
});
