import { autoDarkColors } from "../../modules/theme/color/darkModeColor";
import type { ThemeProps, ThemeVarGetters, ThemeVariant } from "../../modules/theme/types";

import png from "./apple-touch-icon.png?url";
import icon from "./favicon.ico?url";
import favicon from "./favicon.svg?url";
import logoDark from "./logo-dark.svg?url";
import logo from "./logo.svg?url";

export function makeTheme(variant: ThemeVariant) {
    const { isDark } = variant;

    /** These colors can be used by the color functions */
    const baseColors: ThemeVarGetters = autoDarkColors({
        "color-primary": "#579bd3",
        "color-secondary": "#e5e5e5",
    });

    return {
        ...baseColors,

        "logo-image": isDark ? `url("${logoDark}")` : `url("${logo}")`,

        "header-logo-width": "78px",
        "header-logo-height": "18px",

        "sidenav-item-text-color": isDark ? "#FFFFFF" : "var(--color-text)",
        "sidenav-item-before-display": "none",
        "sidenav-item-hover-bg-color": "var(--color-primary)",
        "sidenav-item-hover-icon-color": "#FFFFFF",
        "sidenav-item-hover-text-color": "#FFFFFF",
        "sidenav-item-active-bg-color": "var(--color-primary)",
        "sidenav-item-active-icon-color": "#FFFFFF",
        "sidenav-item-active-text-color": "#FFFFFF",
        "sidenav-item-active-before-display": "none",

        //#region Login
        "login-body-bg-color": "var(--color-white)",
        "login-body-bg-size": "cover",
        "login-body-text-color": "var(--color-white)",
        "login-justify-content": "center",
        "login-align-items": "center",

        "login-intro-display": "none",
        "login-intro-bg-image": "",
        "login-intro-bg-size": "cover",
        "login-intro-bg-position": "50% 50%",
        "login-intro-text-align": "center",
        "login-intro-flex-direction": "column",
        "login-intro-justify-content": "flex-end",
        "login-intro-align-items": "flex-start",
        "login-intro-padding": "60px",
        "login-title-font-size": "36px",
        "login-title-text-color": "",
        "login-title-max-width": "450px",
        "login-title-padding": "0",
        "login-subtitle-font-size": "21px",
        "login-subtitle-max-width": "",

        "login-form-title-font-size": "18px",
        "login-form-bg-color": "var(--color-white)",
        "login-form-text-color": "var(--color-text)",
        "login-form-input-border-color": "#dbe2e9",
        "login-form-button-border-radius": "var(--button-border-radius)",
        "login-form-or-text-color": "#dbe2e9",
        "login-form-or-border-color": "#dbe2e9",
        "login-form-google-bg-color": "#007bf5",
        "login-form-more-text-color": "var(--color-primary)",
        "login-form-more-bg-color": "transparent",
        "login-form-more-border-color": "var(--color-primary)",
        //#endregion
    } satisfies ThemeVarGetters;
}

export const props: ThemeProps = {
    favicon,
    icon,
    png,
    loginLogoImg: {
        src: logo,
        style: {
            maxWidth: "200px",
        },
    },
    baFieldPresentation: {
        integration_id: {
            label: "NForce customer ID",
            description: (
                <span>
                    If you don't have NForce customer ID, please register{" "}
                    <span className="color-primary cursor-pointer">
                        <a href="https://nforce.com/register" target="_blank" rel="noopener noreferrer">
                            here
                        </a>
                    </span>
                    .
                </span>
            ),
        },
    },
};
