import { isStorybook } from "../../../utils/environment.ts";

export const mockVerifyPageMarker = "__MOCK_VERIFY__";

export function isMockVerifyPage(href: string) {
    if (!isStorybook && !import.meta.env.DEV) {
        return false;
    }

    return href.includes(mockVerifyPageMarker);
}
