import C from "./WButton.module.css";

import { useLinkProps } from "@tanstack/react-router";
import { unreachable } from "@warrenio/utils/unreachable";
import React, { forwardRef, type ForwardedRef, type ReactNode, type RefAttributes } from "react";
import { Button, Link, type ButtonProps } from "react-aria-components";
import type { SetOptional } from "type-fest";
import { TodoButtonIcon } from "../../dev/Todo.tsx";
import { isTodoFn } from "../../dev/todoFn.ts";
import { cn } from "../../utils/classNames";
import { usePromiseLoading } from "../../utils/react/usePromiseLoading.ts";
import { vi, type VariantInfoProps } from "../../utils/variants.ts";
import {
    actionIsEvent,
    actionIsLinkProps,
    actionIsRouterLink,
    normalizeAction,
    type Action,
    type RouterLinkProps,
} from "../Action";
import { IconWrapper, type IconProperty } from "../icon/IconWrapper";
import { Spinner } from "../Spinner.tsx";

// eslint-disable-next-line react-refresh/only-export-components
export const vars = vi({
    variants: {
        /** Color */
        color: ["default", "primary", "success", "error", "muted", "sidebar"],
        variant: ["basic", "ghost", "border", "dashed", "editable", "edit", "tableLink"],
        size: ["xs", "md", "lg", "bar", undefined],
        fontSize: ["font-size-small", "font-size-default", "font-size-subtitle", undefined],
        iconSide: ["left", "right", undefined],
    },
    defaults: {
        color: "default",
        variant: "basic",
        size: "md",
        iconSide: "left",
    },
});

type VariantProps = VariantInfoProps<typeof vars>;

export interface ButtonLookProps extends VariantProps {
    children?: ReactNode;
    className?: string;
    /** Short-hand property equivalent to {@link children} for better-looking code for simple buttons. */
    label?: ReactNode;
    isLoading?: boolean;
    isDisabled?: boolean;
    ariaLabel?: string;
    icon?: IconProperty;
    isTodo?: boolean;
    "data-testid"?: string;
}

/**
 * (set {@link slot} to `null` to prevent the button from becoming eg. a popover trigger)
 */
export interface RegularButtonProps extends ButtonLookProps, Pick<ButtonProps, "type" | "autoFocus" | "slot"> {
    slot?: string | null;
}

export interface LinkButtonProps extends ButtonLookProps {}

// XXX: It's actually either a `RegularButtonProps` or a `LinkButtonProps`, but use an interface instead because
// otherwise derived component types work badly in TypeScript (when inheriting from an exclusive union type).
export interface WButtonProps extends RegularButtonProps, LinkButtonProps {
    action: Action | undefined;
}

export const WButton = forwardRef(function WButton(
    {
        children,
        className,
        label,
        color = vars.defaults.color,
        variant = vars.defaults.variant,
        size = vars.defaults.size,
        icon,
        iconSide = vars.defaults.iconSide,
        fontSize,
        isLoading: isLoadingProp,
        isDisabled,
        ariaLabel,
        action,
        isTodo,
        ...props
    }: WButtonProps,
    ref: ForwardedRef<HTMLAnchorElement | HTMLButtonElement>,
) {
    const { isPromiseLoading, registerPromise } = usePromiseLoading();

    const isLoading = isLoadingProp ?? isPromiseLoading;

    const hasChildren = React.Children.count(children) > 0 || !!label;

    const variantCns = cn(
        C[color],
        C[variant],
        C[size],
        fontSize && C[fontSize],
        !hasChildren && C.square,
        isDisabled && C.disabled,
    );
    const commonCns = cn(C.Button, className, variantCns);

    const childrenWrapper = (
        <span key="children" className={C.buttonText}>
            {label}
            {children}
        </span>
    );

    let childrenWithIcon;
    if (icon) {
        const iconSpacer = hasChildren ? <span key="spacer" className={C.iconSpacer} /> : null;
        const iconElement = <IconWrapper key="icon" icon={icon} className={cn(C.iconWrapper, variantCns)} />;

        childrenWithIcon = [iconElement, iconSpacer, childrenWrapper];
        if (iconSide === "right") {
            childrenWithIcon.reverse();
        }
    } else {
        childrenWithIcon = [childrenWrapper];
    }

    if (import.meta.env.DEV && (isTodo ?? isTodoFn(action))) {
        childrenWithIcon.push(<TodoButtonIcon key="todoIcon" className="ml-1" />);
    }

    if (isLoading) {
        // TODO: handle `isDisabled`
        childrenWithIcon.push(<Spinner key="spinner" />);
    }

    const commonProps = {
        "data-loading": isLoading ? true : undefined,
        "aria-label": ariaLabel,
        "data-testid": props["data-testid"],
        children: childrenWithIcon,
        className: commonCns,
        isDisabled: !!isDisabled || isLoading,
    };

    action = normalizeAction(action);

    if (actionIsRouterLink(action)) {
        return <AriaRouterLink ref={ref as ForwardedRef<HTMLAnchorElement>} action={action} {...commonProps} />;
    }
    if (actionIsLinkProps(action)) {
        return <Link ref={ref as ForwardedRef<HTMLAnchorElement>} {...commonProps} {...action} />;
    }
    if (action == null || actionIsEvent(action)) {
        const { type, autoFocus, slot } = props as RegularButtonProps;
        return (
            <Button
                ref={ref as ForwardedRef<HTMLButtonElement>}
                {...commonProps}
                onPress={(e) => registerPromise(action?.(e))}
                type={type}
                autoFocus={autoFocus}
                slot={slot}
                {...props}
            />
        );
    }
    unreachable();
});

export type WSubmitButtonProps = SetOptional<WButtonProps, "action">;

/** WButton equivalent of a form's submit button */
export function WSubmitButton(props: WSubmitButtonProps) {
    return <WButton type="submit" color="primary" action={undefined} {...props} />;
}

interface AriaRouterLinkProps extends RefAttributes<HTMLAnchorElement> {
    className?: string;
    action: RouterLinkProps;
    isDisabled?: boolean;
}

const defaultLinkProps = {
    // Trust router links by default
    rel: "opener",
} as const;

/** Component that connects Tanstack Router to react-aria's Link */
const AriaRouterLink = forwardRef(function AriaRouterLink(
    { action, isDisabled, ...props }: AriaRouterLinkProps,
    ref: ForwardedRef<HTMLAnchorElement>,
) {
    // Type of `onPress` is not suitable for Tanstack Router since it expects a mouse event in its `onClick` handler - so would need to use Tanstack's `onClick` as the outermost handler.
    // Unfortunately the <Link> component from react-aria can not use a custom `onClick` but instead always shows a deprecation warning.
    const {
        onFocus: _ignored1,
        onBlur: _ignored2,
        className: _ignored3,
        ...routerProps
    } = useLinkProps({
        ...defaultLinkProps,
        ...(action as Parameters<typeof useLinkProps>[0]),
        disabled: isDisabled,
    });
    // console.debug("WButton: %o", { routerProps });
    return <Link ref={ref} isDisabled={isDisabled} {...routerProps} {...props} />;
});
